<template>
  <UButton
    :icon="
      isDark ? 'i-j-theme-sun-moon-typemoon' : 'i-j-theme-sun-moon-typesun'
    "
    color="gray"
    variant="ghost"
    aria-label="Theme"
    @click="isDark = !isDark"
  />
</template>
<script setup>
const colorMode = useColorMode()

const isDark = computed({
  get() {
    return colorMode.value === 'dark'
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  },
})
</script>
