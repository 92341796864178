<template>
  <div class="bg-block-4">
    <div class="border-b border-b-block-3">
      <div
        class="container mx-auto flex w-full flex-col justify-between gap-4 py-4 md:flex-row md:items-center"
      >
        <div class="flex items-center gap-4">
          <div class="relative">
            <div
              class="ping flex h-10 w-10 items-center justify-center rounded-full bg-[#46B83D1A]"
            ></div>
            <div
              class="absolute inset-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rounded-full bg-[#68C161]"
            ></div>
          </div>
          <div class="flex flex-col">
            <span class="t-medium text-text-2">
              {{ t('c.serviceStatus') }}
            </span>
            <span class="t-body__bold text-text-1">
              {{ t('c.systemsOperational') }}
            </span>
          </div>
        </div>

        <UButton
          class="self-start md:self-end"
          variant="ghost"
          size="lg"
          trailing-icon="i-heroicons-arrow-right"
          to="https://status.juno.one/"
          target="_blank"
        >
          {{ t('c.checkStatus') }}
        </UButton>
      </div>
    </div>

    <UFooter :ui="{ top: { container: 'container max-w-unset', wrapper: '' } }">
      <template #top>
        <div class="flex flex-col gap-8 lg:flex-row">
          <div class="flex min-w-60 max-w-xs flex-col gap-8">
            <Logo />
            <span class="t-body text-text-2">
              {{ t('c.designedForProfessionals') }}
            </span>
            <div class="flex items-center gap-2">
              <NuxtLink
                v-for="(site, index) in socialSites"
                :key="index"
                :to="site.to"
                :aria-label="site.ariaLabel"
                external
              >
                <UIcon
                  class="mr-2 h-6 w-6"
                  :name="site.icon"
                />
              </NuxtLink>
            </div>
          </div>
          <UFooterColumns
            :ui="{
              wrapper: 'xl:grid xl:grid-cols-4 xl:gap-8',
              center:
                'flex flex-col lg:grid grid-flow-col auto-cols-fr gap-8 xl:col-span-4',
            }"
            class="w-full"
            :links="links"
          />
        </div>
      </template>
      <template #right>
        <div class="flex items-center">
          <SvgEu class="w-5" />
          <UButton
            variant="link"
            color="white"
            :to="localePath('/eu-funds')"
          >
            {{ t('c.euFunds.link') }}
          </UButton>
          <!--           <span class="t-body text-text-2"> </span> -->
        </div>
      </template>

      <template #left>
        <span class="t-body text-text-2"
          >© juno.one 2024 | Powered by
          <NuxtLink
            class="hover:underline"
            target="_blank"
            to="https://www.denevy.eu/"
            external
          >
            Denevy
          </NuxtLink>
        </span>
      </template>
    </UFooter>
  </div>
</template>
<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

const links = computed(() => [
  {
    label: t('c.product'),
    children: [
      {
        label: t('c.issueManagement'),
        to: localePath('/features/issue'),
      },
      {
        label: t('c.roadmapPlanning'),
        to: localePath('/features/roadmap'),
      },
      {
        label: t('c.qualityTesting'),
        to: localePath('/features/testing'),
      },
      {
        label: t('c.timeManagement'),
        to: localePath('/features/timesheet'),
      },
      {
        label: t('c.projectDocumentation'),
        to: localePath('/features/docs'),
      },
      {
        label: t('c.dashboard'),
        to: localePath('/features/dashboard'),
      },
    ],
  },

  {
    label: t('c.support'),
    children: [
      {
        label: t('c.contacts'),
        to: localePath('/help/contacts'),
      },
      {
        label: t('c.faq'),
        to: localePath('/help/faq'),
      },
      {
        label: t('c.documentation'),
        to: localePath('/documentation/getting-started'),
      },
      {
        label: t('c.helpCustomerSupport'),
        to: localePath('/help/contacts?form=customer-support'),
      },
      {
        label: t('c.helpReportABug'),
        to: localePath('/help/contacts?form=report-a-bug'),
      },
      {
        label: t('c.giveFeedback'),
        to: localePath('/help/contacts?form=share-an-idea'),
      },
    ],
  },
  {
    label: t('c.policies'),
    children: [
      {
        label: t('c.privacyPolicy'),
        to: localePath('/documentation/policies/gdpr'),
      },
      {
        label: t('c.termsOfUse'),
        to: localePath('/documentation/policies/terms-and-conditions'),
      },
      {
        label: t('c.securityPolicy'),
        to: localePath('/documentation/policies/security-policy'),
      },
      {
        label: t('c.customOnPremise'),
        to: localePath('/documentation/policies/custom-installation'),
      },

      {
        label: t('c.cloudProtection'),
        to: localePath('/documentation/policies/cloud-protection'),
      },
      {
        label: t('c.licenseTerms'),
        to: localePath('/documentation/policies/license-terms'),
      },
    ],
  },
  {
    label: t('c.partnershipAndCareer'),
    children: [
      {
        label: t('c.ambassador'),
        to: localePath('/partner?type=ambassador'),
      },
      {
        label: t('c.consultant'),
        to: localePath('/partner?type=consultant'),
      },
      {
        label: t('c.reseller'),
        to: localePath('/partner?type=reseller'),
      },
      {
        label: t('c.solutionPartner'),
        to: localePath('/partner?type=reseller'),
      },
      {
        label: t('c.career'),
        to: 'https://denevy.recruitee.com/',
        target: '_blank',
      },
    ],
  },
])

const socialSites = [
  {
    to: 'https://www.facebook.com/juno1one',
    icon: 'i-j-facebook',
    ariaLabel: 'Facebook',
  },
  {
    to: 'https://www.linkedin.com/company/juno-one/mycompany/',
    icon: 'i-j-linkedin',
    ariaLabel: 'LinkedIn',
  },
  {
    to: 'https://www.instagram.com/juno.one.official',
    icon: 'i-j-instagram',
    ariaLabel: 'Instagram',
  },
  {
    to: 'https://twitter.com/junooneofficial',
    icon: 'i-j-x-twitter',
    ariaLabel: 'Twitter',
  },
  {
    to: 'https://www.youtube.com/channel/UCxqChTI104TuplaT7-B9nSA',
    icon: 'i-j-youtube',
    ariaLabel: 'YouTube',
  },
]
</script>
<style scoped lang="scss">
.ping {
  animation: online-ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes online-ping {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}
</style>
